* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a {
    color: #4A90E2;
    text-decoration: none;
}

body {
    color: #333;
    background: #555;
    font-family: 'Roboto', sans-serif;
    height: 100%;
    -webkit-font-smoothing: antialiased;
}

input {
    background: #F1F1F1;
    border: none;
    border-bottom: 1px solid #EEE;
    display: block;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    height: 33px;
    margin-bottom: 5px;
    padding-left: 10px;
    transition: all 0.2s;
    width: 100%;
}

input:focus {
    background: #FFF;
    border-bottom: 1px solid #333;
    outline: none;
}

input:hover {
    background: #FFF;
    border-bottom: 1px solid #AAA;
}

button,
input[type=submit] {
    background: #eb6159;
    border: none;
    border-radius: 4px;
    color: #FFF;
    cursor: pointer;
    display: block;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    height: 33px;
    margin-bottom: 5px;
    padding-left: 0;
    transition: all 0.2s;
    width: 100%;
}

button:hover,
input[type=submit]:hover {
    background: #555;
}

form {
    margin-bottom: 10px;
    padding: 0 10px;
}

h1 {
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 10px;
}

p {
    margin-bottom: 5px;
}

p span {
    font-weight: 700;
}

.error {
    color: #D0011B;
    font-size: 14px;
    padding: 5px;
    text-align: center;
}

.success {
    color: #71BD1E;
    font-size: 14px;
    padding: 5px;
    text-align: center;
}
.success-h1 {
    color: #71BD1E;
    padding: 5px;
    text-align: center;
}

.Spinner {
    margin: 10px;
}

.three-bounce > div {
    background: #eb6159;
}

.sk-three-bounce > div {
    background: #eb6159;
}

.App {
    border: 3px solid #FAFAFA;
    border-radius: 4px;
    height: 100%;
    margin: 10px auto;
    max-width: 400px;
    padding: 10px;
    background: #FFFFFF;

}

.Home-section {
    margin-top: 10px;
    text-align: center;
}

.ReCaptcha {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}